import * as yup from 'yup';
import { diffInYearsAgainstToday, getDateWithoutTime, isDateValid } from '../../../utils/datetime';

export function dobAdult(label = 'Date of birth') {
  let res = yup
    .date()
    .transform((value) => (isDateValid(value) ? getDateWithoutTime(value) : new Date('')))
    .test(
      'dob',
      'Please enter your date of birth (You must be at least 18 years of age)',
      value => diffInYearsAgainstToday(value) >= 18,
    )
    .test(
      'dob',
      'Please enter your date of birth (You should not be older than 120 years)',
      value => diffInYearsAgainstToday(value) <= 100,
    );
  if (label) {
    res = res.label(label);
  }
  return res;
}
