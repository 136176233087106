import * as yup from 'yup';

export default function email(label) {
  let res = yup
    .string()
    .ensure()
    .trim()
    .matches(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter a valid email address.',
    );
  if (label) {
    res = res.label(label);
  }
  return res;
}
