import * as yup from 'yup';

export default function string(label) {
  let res = yup
    .string()
    .ensure()
    .trim()
    .transform(v => v.replace(/\s+/g, ' ').replace(/\s([.,!?])/g, '$1'));
  if (label) {
    res = res.label(label);
  }
  return res;
}
