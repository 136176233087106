import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { storeUser } from 'src/js/actions/UserActions';
import { authenticateUser } from 'src/js/endpoints/users';
import { useBrand } from 'src/js/nextgen/providers/BrandProvider';
import Analytics from 'src/js/utils/analytics/Analytics';
import { apiUrl } from 'src/js/utils';
import { mutate } from 'swr';
import * as cookieState from 'utils/cookieState';
import useCommonAnalyticsAttributes from 'utils/hooks/useCommonAnalyticsAttributes';

export const useQueryParams = () => {
  const router = useRouter();
  return (queryParam: string, value: any) => router.replace({
    query: { ...router.query, [queryParam]: value },
  }, undefined, { shallow: false });
};

const API_CLIENT_ID = process.env.NEXT_PUBLIC_API_CLIENT_ID;
const API_CLIENT_SECRET = process.env.NEXT_PUBLIC_API_CLIENT_SECRET;
const GENERIC_API_ERROR = (support_phone: string) => `Please refresh the page and try again or call support to get help at ${support_phone}.`;

type PasswordAuthArgs = {
  email: string;
  password: string;
  otp_code?: never;
  isPasswordless?: false;
};

type PasswordlessAuthArgs = {
  email: string;
  otp_code: string;
  password?: never;
  isPasswordless: true;
}

type AuthArgs = PasswordAuthArgs | PasswordlessAuthArgs;

const OTP_DESTINATIONS = {
  EMAIL: 'email',
  SMS: 'sms',
} as const;

export const useSignIn = () => {
  const { support_phone } = useBrand();
  const dispatch = useDispatch();
  const { reloadAttributes } = useCommonAnalyticsAttributes();

  return async ({
    email, password, otp_code, isPasswordless = false,
  }: AuthArgs) => {
    const postData = isPasswordless ? {
      email,
      is_passwordless: true,
      otp_code,
      destinations: [OTP_DESTINATIONS.EMAIL],
      client_id: API_CLIENT_ID as string,
      client_secret: API_CLIENT_SECRET as string,
    } : {
      email,
      password,
      is_passwordless: false,
      client_id: API_CLIENT_ID as string,
      client_secret: API_CLIENT_SECRET as string,
    };
    try {
      const { data } = await authenticateUser(postData);
      await Promise.all([
        Analytics.identify(data),
        reloadAttributes(),
      ]);
      const coverage = data.userprofile?.coverage.find((c: any) => !c.is_expired);
      if (coverage) {
        cookieState.set('payer', { id: coverage.payer_id, name: coverage.payer_name });
      } else {
        cookieState.clear('payer');
      }
      dispatch(storeUser(data));
      await mutate(apiUrl.getUserData, data, false);
      return data;
    } catch (error: Error | any) {
      if (!error.response) {
        // Catch only unhandled error
        Analytics.authException(error);
        throw GENERIC_API_ERROR(support_phone);
      }
      const { response } = error;
      if (response.status >= 500) {
        // Catch only unhandled error
        Analytics.authException(error);
        throw GENERIC_API_ERROR(support_phone);
      }
      throw response.data || GENERIC_API_ERROR(support_phone);
    }
  };
};

export const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
};
