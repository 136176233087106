import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { LocalStorage } from 'src/js/utils/storage';
import * as YupHelpers from 'src/js/nextgen/forms/YupHelpers';

export const checkURLForError = (fullURL: string) => {
  const errorInURL = fullURL.match('error_message=(.*?(&|$))');
  // check first capture group existance - lop off & since it might get included
  const errorText = errorInURL && errorInURL[1] ? errorInURL[1].replace(/%20/g, ' ').split('&')[0] : '';
  return errorText;
};

export const loginValidationSchema = Yup.object().shape({
  email: YupHelpers.string().required('Please enter a valid email.'),
  password: Yup.string().required('Please enter a valid password.'),
});

export const MAX_VALIDATE_OTP_ATTEMPTS = 5;
export const OTP_STORAGE_LABEL = 'pc_otp_attempts';
const OTP_WINDOW_MINUTES = 30;

type OTPAttemptsLog = {
  attempts: number;
  remainingAttempts: number;
  incrementAttempts: () => void;
  expiry: Date;
  remainingWindowMinutes: () => number;
}

// Client-side rate limiting for requesting OTPs. This shouldn't be relied upon (like all frontend validation) but it
// should minimize the load on API somewhat. Rate limits users to 5 attempts within a static 30 minute window.
export const useValidateOTPAttempts = (): OTPAttemptsLog => {
  const expiryDate = new Date();
  expiryDate.setMinutes(expiryDate.getMinutes() + OTP_WINDOW_MINUTES);
  const expiry = useRef(expiryDate);
  const data = LocalStorage.getItem(OTP_STORAGE_LABEL) as { attempts: number, expiry: number };
  const [attempts, setAttempts] = useState(data?.attempts || 0);

  if (data?.expiry) {
    expiry.current = new Date(data.expiry);
  }

  if ((expiry.current.getTime() + OTP_WINDOW_MINUTES * 60 * 1000) <= expiryDate.getTime()) {
    expiry.current = expiryDate;
    setAttempts(0);
    LocalStorage.setItem(OTP_STORAGE_LABEL, { attempts: 0, expiry: expiry.current });
  }

  return {
    attempts,
    remainingAttempts: MAX_VALIDATE_OTP_ATTEMPTS - attempts,
    incrementAttempts: () => {
      setAttempts(a => a + 1);
      LocalStorage.setItem(OTP_STORAGE_LABEL, { attempts: attempts + 1, expiry: expiry.current });
    },
    expiry: expiry.current,
    remainingWindowMinutes: () => Math.floor((expiry.current.getTime() - Date.now()) / (60 * 1000)),
  };
};
