import styled from '@emotion/styled';
import { Form } from 'src/js/nextgen/forms';

import Input from 'src/js/nextgen/plushcare-components/Input';
import UnmaskedInput from 'src/js/nextgen/plushcare-components/UnmaskedInput';

export const LoginFormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 500px;
  color: ${props => props.theme.colors.foreground};
  margin: 35px 20px 0 20px;

h2, p {
  width: 100%;
  text-align: center;
}

h2 {
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
}

.forgot-password {
  width: 100%;
  margin-top: -5px;
  margin-bottom: 40px;
  text-align: left;
  display: block;
  font-size: 12px;
}

.tos-text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 11px;
  line-height: 13px;
}

.create-profile {
  margin-top: 24px;
}

.continue-button {
  margin: 0 18px 20px;
}

.error-message {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
`;

// TODO:: use TextInput here
export const EmailInput = styled(Input)`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;
`;

export const PasswordInput = styled(UnmaskedInput)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0;
`;

export const ContinueSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
